import {
  type DataLoggerConfiguration,
  type FarmPrecipitationResponse,
  type MoistureSensorConfiguration,
  type ObservationSite,
  type SoilDataSource,
} from '@soilsense/shared';
import { stringify } from 'csv-stringify/sync';
import deepEqual from 'deep-equal';
import { action, comparer, computed, makeObservable, observable, toJS } from 'mobx';
import type { IPromiseBasedObservable } from 'mobx-utils';
import { fromPromise } from 'mobx-utils';
import moment from 'moment';
import type { IValidatedDateRangeObj } from '../components/RangeDatePicker';
import { DEFAULT_DEPTHS_BY_SENSOR_COUNT } from '../components/Settings/SensorDepths';
import type { IDataElement } from '../interfaces/IDataElement';
import { DATA_ELEMENTS_DECODER } from '../interfaces/IDataElement';
import type { FirebaseTokenProvider } from '../interfaces/IUser';
import type { Eventually } from '../utils/Eventually';
import assertNever from '../utils/assertNever';
import { BASE_URL } from '../utils/consts';
import { CError, Errors } from '../utils/errors';
import { fetchAndSet } from '../utils/fetchAndSet';
import getErrorMessage from '../utils/getErrorMessage';
import type { FarmStore } from './FarmStore';
import type { PrecipitationBuckets } from './Precipitation';
import { createPrecipitationBuckets } from './Precipitation';
import type { IMinMax, NameMap, SingleSensorData } from './SensorTransformer';
import {
  dataContainsBoxTemperature,
  dataContainsMoisture,
  dataContainsSalinity,
  getTransformedData,
} from './SensorTransformer';
import type { UserStore } from './UserStore';
import {
  BOX_TEMPERATURE,
  PLANT_AVAILABLE_WATER_BOT,
  PLANT_AVAILABLE_WATER_MID,
  PLANT_AVAILABLE_WATER_MID_BOT,
  PLANT_AVAILABLE_WATER_TOP,
  SALINITY_BOT,
  SALINITY_MID,
  SALINITY_MID_BOT,
  SALINITY_TOP,
  TEMPERATURE_BOT,
  TEMPERATURE_MID,
  TEMPERATURE_MID_BOT,
  TEMPERATURE_TOP,
  VOLUMETRIC_WATER_CONTENT_BOT,
  VOLUMETRIC_WATER_CONTENT_MID,
  VOLUMETRIC_WATER_CONTENT_MID_BOT,
  VOLUMETRIC_WATER_CONTENT_TOP,
} from './utils/constsAndTypes';
import type { DeviceIdentifiers } from './utils/formatters';
import { customerVisibleDeviceId } from './utils/formatters';

export type ObservationSiteWithId = ObservationSite & Readonly<{ id: string }>;

export type SiteInfo = Readonly<{
  site: ObservationSiteWithId;
  deviceIds: DeviceIdentifiers;
  savedConfiguration: DataLoggerConfiguration;
  configuration: DataLoggerConfiguration;
  nameMap: NameMap;
}>;

export type SiteExportOptions = Readonly<{
  includeSalinity: boolean;
  includeSoilTemperature: boolean;
  includeAirTemperature: boolean;
}>;

export type SiteDetails = SiteInfo &
  Readonly<{
    kind: 'active' | 'archived';
    transformed: Eventually<SingleSensorData>;
    loading: boolean;
    error: string | undefined;
    errorObject: any;
    fieldId: string | undefined;
    setPendingUpdate: (update: PendingSiteUpdate) => void;
    toCsv: (options: SiteExportOptions) => string;
  }>;

export type SiteIdentifiers = Readonly<{
  id: string;
  deviceIds: DeviceIdentifiers;
}>;

export type PendingSiteUpdate = Partial<DataLoggerConfiguration>;

type RawObservationSiteData = Readonly<{
  dateRange: IValidatedDateRangeObj;
  rawData: readonly IDataElement[];
  rainfall: PrecipitationBuckets;
  irrigation: PrecipitationBuckets;
}>;

export class UpdatableSiteDetails implements SiteDetails {
  @observable private observationSite: ObservationSiteWithId;
  @observable private pendingUpdate: PendingSiteUpdate = {};
  @observable private rawData: IPromiseBasedObservable<RawObservationSiteData> | undefined;

  constructor(
    observationSite: ObservationSiteWithId,
    private readonly userStore: UserStore,
    private readonly farmStore: FarmStore
  ) {
    makeObservable(this);
    this.observationSite = observationSite;
  }

  @action updateRawData(): void {
    const { currentUser, currentTokenProvider } = this.userStore;
    if (currentUser == undefined || currentTokenProvider == undefined) {
      throw new CError('Cannot fetch sensor data: No user is logged in', {
        code: Errors.CANNOT_FETCH_SENSOR_DATA_NO_USER_LOGGED_IN,
        details: `Farm ID ${this.farmStore.selectedFarm?.id}`,
      });
    }
    if (this.farmStore.precipitation == undefined) {
      throw new CError('Cannot fetch sensor data: No precipitation data available', {
        code: Errors.CANNOT_FETCH_SENSOR_DATA_NO_PRECIPITATION_DATA,
        details: `Farm ID ${this.farmStore.selectedFarm?.id}`,
      });
    }
    this.rawData = fromPromise(
      fetchRawData(
        toJS(this.siteIdentifiers),
        toJS(this.dateRange),
        this.farmStore.precipitation,
        currentTokenProvider,
        this.farmStore.selectedFarm?.showRainfall ?? true,
        currentUser.showRainfallFromWeatherObservations ?? false
      )
    );
  }

  @action setObservationSite(site: ObservationSiteWithId): void {
    if (deepEqual(this.observationSite, site) == false) {
      this.observationSite = site;
      this.pendingUpdate = {};
    }
  }

  @action setPendingUpdate(update: PendingSiteUpdate): void {
    if (deepEqual(update, this.pendingUpdate) == false) {
      this.pendingUpdate = update;
    }
  }

  @computed private get siteMetadata(): Readonly<
    | {
        kind: 'active';
        soilDataSource: SoilDataSource;
      }
    | { kind: 'archived'; archivedTimestamp: number; soilDataSource: SoilDataSource }
  > {
    const { soilDataSourceHistory } = this.site;
    const historyLength = soilDataSourceHistory.length;
    const lastSource = soilDataSourceHistory[historyLength - 1];
    if (lastSource?.value != undefined) {
      return { kind: 'active', soilDataSource: lastSource.value };
    }
    const archivedTimestamp = lastSource.startTimestamp;

    const previousSource = soilDataSourceHistory[historyLength - 2];
    if (previousSource?.value != undefined) {
      return { kind: 'archived', archivedTimestamp, soilDataSource: previousSource.value };
    }

    throw new CError(`Could not find data source on observation site. ID ${this.site.id}`, {
      code: Errors.NO_DATA_SOURCE_ON_OBSERVATION_SITE,
      details: `Observation site ID ${this.site.id}`,
    });
  }

  @computed get soilDataSource(): SoilDataSource {
    return this.siteMetadata.soilDataSource;
  }

  @computed get kind(): 'active' | 'archived' {
    return this.siteMetadata.kind;
  }

  @computed get site(): ObservationSiteWithId {
    return this.observationSite;
  }

  @computed get deviceIds(): DeviceIdentifiers {
    const { deviceNumber, deviceName } = this.soilDataSource;
    return { id: deviceNumber.toString(), deviceName };
  }

  @computed get siteIdentifiers(): SiteIdentifiers {
    return {
      id: this.site.id,
      deviceIds: this.deviceIds,
    };
  }

  @computed get savedConfiguration(): DataLoggerConfiguration {
    return this.soilDataSource.configuration;
  }

  @computed get configuration(): DataLoggerConfiguration {
    return {
      ...this.savedConfiguration,
      ...this.pendingUpdate,
    };
  }

  @computed get nameMap(): NameMap {
    return createDataLoggerNameMap(this.savedConfiguration);
  }

  @computed
  get transformed(): Eventually<SingleSensorData> {
    if (this.rawData == undefined) {
      return { status: 'error', errorMessage: 'No data available' };
    }

    switch (this.rawData.state) {
      case 'pending':
        return { status: 'pending' };

      case 'fulfilled': {
        const { dateRange, rawData, rainfall, irrigation } = this.rawData.value;
        console.log({ rawData });
        return {
          status: 'ready',
          value: {
            rainfall,
            irrigation,
            ...getTransformedData(
              dateRange,
              this.site,
              this.configuration,
              rawData,
              this.farmStore.selectedFarm?.amendSalinity ?? false,
              this.farmStore.selectedFarm?.showHistoricalCalibrations ?? true
            ),
            containsMoisture: dataContainsMoisture(rawData),
            containsSalinity: dataContainsSalinity(rawData),
            containsBoxTemperature: dataContainsBoxTemperature(rawData),
          },
        };
      }

      case 'rejected':
        return {
          status: 'error',
          errorMessage: getErrorMessage(this.rawData.value),
          errorObject: this.rawData.value,
        };

      default:
        return assertNever(this.rawData);
    }
  }

  @computed get loading(): boolean {
    return this.transformed.status == 'pending';
  }

  @computed get error(): string | undefined {
    return this.transformed.status == 'error' ? this.transformed.errorMessage : undefined;
  }

  @computed get errorObject(): any {
    return this.transformed.status == 'error' ? this.transformed.errorObject : undefined;
  }

  @computed get fieldId(): string | undefined {
    return this.farmStore.siteIdToFieldId.get(this.site.id);
  }

  @computed private get dateRange(): IValidatedDateRangeObj {
    const hideDataBefore = this.hideDataBefore;
    let { startDate, endDate } = this.farmStore.selectedDates;
    startDate = startDate.clone().startOf('day');
    if (startDate.isBefore(hideDataBefore)) {
      startDate = moment(hideDataBefore);
    }
    // to include the last selected day in the lower or equal condition add 1 day
    endDate = endDate.clone().add(1, 'day').startOf('day');
    if (this.siteMetadata.kind == 'archived' && endDate.isAfter(this.siteMetadata.archivedTimestamp)) {
      endDate = moment(this.siteMetadata.archivedTimestamp);
    }
    const now = moment();
    if (endDate.isAfter(now)) {
      endDate = now;
    }
    return { startDate, endDate };
  }

  @computed private get hideDataBefore(): number {
    const firstSoilDataSource = this.site.soilDataSourceHistory[0];
    if (firstSoilDataSource == undefined) {
      throw new Error('Observation site has no soil data source');
    }
    return firstSoilDataSource.startTimestamp;
  }

  toCsv(options: SiteExportOptions): string {
    if (this.transformed.status != 'ready') {
      throw new Error('Observation data is not available');
    }
    const includeSalinity = options.includeSalinity && this.transformed.value.containsSalinity;
    const includeSoilTemperature = options.includeSoilTemperature;
    const includeAirTemperature = options.includeAirTemperature && this.transformed.value.containsBoxTemperature;
    const middleSensor = this.configuration.cableMiddle != undefined;

    const rows: (string | number)[][] = [
      ['Timestamp'].concat(
        middleSensor
          ? percentLabels(this.nameMap.vwcTop, this.nameMap.vwcMid, this.nameMap.vwcBot)
          : percentLabels(this.nameMap.vwcTop, this.nameMap.vwcBot),
        includeSalinity
          ? middleSensor
            ? salinityLabels(this.nameMap.salTop, this.nameMap.salMid, this.nameMap.salBot)
            : salinityLabels(this.nameMap.salTop, this.nameMap.salBot)
          : [],
        includeSoilTemperature
          ? middleSensor
            ? temperatureLabels(this.nameMap.tempTop, this.nameMap.tempMid, this.nameMap.tempBot)
            : temperatureLabels(this.nameMap.tempTop, this.nameMap.tempBot)
          : [],
        includeAirTemperature ? temperatureLabels(this.nameMap.airTemp) : []
      ),
    ];

    for (const each of this.transformed.value.data) {
      rows.push(
        ([] as (string | number)[]).concat(
          [new Date(each.timestamp).toISOString()],
          middleSensor
            ? [each.vwcTop ?? '', each.vwcMid ?? '', each.vwcBot ?? '']
            : [each.vwcTop ?? '', each.vwcBot ?? ''],
          includeSalinity
            ? middleSensor
              ? [each.salTop ?? '', each.salMid ?? '', each.salBot ?? '']
              : [each.salTop ?? '', each.salBot ?? '']
            : [],
          includeSoilTemperature
            ? middleSensor
              ? [each.tempTop ?? '', each.tempMid ?? '', each.tempBot ?? '']
              : [each.tempTop ?? '', each.tempBot ?? '']
            : [],
          includeAirTemperature ? [each.airTemp ?? ''] : []
        )
      );
    }
    return stringify(rows);
  }
}

async function fetchRawData(
  siteIds: SiteIdentifiers,
  dateRange: IValidatedDateRangeObj,
  precipitationPromise: Promise<FarmPrecipitationResponse>,
  getIdToken: FirebaseTokenProvider,
  showRainfallFromGateway: boolean,
  showRainfallFromWeatherObservations: boolean
): Promise<RawObservationSiteData> {
  const { startDate, endDate } = dateRange;
  const token = await getIdToken();
  const [response, precipitationResponse] = await Promise.all([
    fetchAndSet(
      `${BASE_URL}/observation-sites/${siteIds.id}/observations/${startDate.format('x')}-${endDate.format('x')}`,
      token
    ),
    precipitationPromise,
  ]);

  const rawData = await DATA_ELEMENTS_DECODER.decodeToPromise(response);
  if (rawData.length == 0) {
    throw new CError(
      `No data was found for the selected dates. Sensor ${customerVisibleDeviceId(siteIds.deviceIds)}`,
      {
        code: Errors.NO_DATA_FOR_SELECTED_DATES,
        details: `Sensor ${customerVisibleDeviceId(siteIds.deviceIds)}`,
      }
    );
  }

  const rawRainfallFromWeatherObservations = precipitationResponse.rainfallFromWeatherObservations[siteIds.id];
  const rainfall = showRainfallFromGateway
    ? createPrecipitationBuckets(precipitationResponse.rainfall, dateRange)
    : showRainfallFromWeatherObservations && rawRainfallFromWeatherObservations != undefined
    ? createPrecipitationBuckets(rawRainfallFromWeatherObservations, dateRange)
    : [];
  let irrigation: PrecipitationBuckets = [];
  const observations = precipitationResponse.irrigations[siteIds.id];
  if (observations != undefined) {
    irrigation = createPrecipitationBuckets(observations, dateRange);
  }
  return { dateRange, rawData, rainfall, irrigation };
}

export class ObservationSiteStore {
  @observable private fetchingArchivedData = false;
  @observable private readonly siteIdToDetails: Map<string, UpdatableSiteDetails> = new Map();

  constructor(private readonly userStore: UserStore, private readonly farmStore: FarmStore) {
    makeObservable(this);
  }

  @computed get showArchivedSites(): boolean {
    return this.fetchingArchivedData;
  }

  @action setShowArchivedSites(showArchivedSites: boolean): void {
    this.fetchingArchivedData = showArchivedSites;
    if (showArchivedSites) {
      for (const siteDetails of this.allSiteDetails) {
        if (siteDetails.kind == 'archived') {
          siteDetails.updateRawData();
        }
      }
    }
  }

  getSiteInfo(siteId: string): SiteDetails | undefined {
    return this.siteIdToDetails.get(siteId);
  }

  getSiteInfoByDeviceId(deviceId: string): SiteInfo | undefined {
    return this.activeSiteDetails.find((siteInfo) => siteInfo.deviceIds.id == deviceId);
  }

  @computed({ equals: comparer.structural })
  get allSiteDetails(): readonly UpdatableSiteDetails[] {
    return Array.from(this.siteIdToDetails.values());
  }

  @computed({ equals: comparer.structural })
  get activeSiteDetails(): readonly SiteDetails[] {
    return this.allSiteDetails.filter((site) => site.kind == 'active');
  }

  @computed({ equals: comparer.structural })
  get archivedSiteDetails(): readonly SiteDetails[] {
    return this.allSiteDetails.filter((site) => site.kind == 'archived');
  }

  @computed({ equals: comparer.structural })
  get activeSiteIdentifiers(): readonly SiteIdentifiers[] {
    return this.activeSiteDetails.map(({ site, deviceIds }) => ({
      id: site.id,
      deviceIds,
    }));
  }

  @action updateData(): void {
    if (this.userStore.currentUser == undefined) {
      this.siteIdToDetails.clear();
      return;
    }
    for (const siteDetails of this.allSiteDetails) {
      if (this.fetchingArchivedData || siteDetails.kind != 'archived') {
        siteDetails.updateRawData();
      }
    }
  }

  @action updateSiteDetails(): void {
    const farm = this.farmStore.selectedFarm;
    if (
      this.userStore.currentUser == undefined ||
      farm?.observationSites == undefined ||
      farm?.observationSiteOrder == undefined
    ) {
      this.siteIdToDetails.clear();
      return;
    }

    const siteIdsToKeep: Set<string> = new Set();
    const { observationSites, observationSiteOrder } = farm;
    for (const id of observationSiteOrder) {
      const site = observationSites[id];
      if (site == undefined) {
        continue;
      }

      siteIdsToKeep.add(id);
      const siteWithId = { id, ...site };
      const siteInfo = this.siteIdToDetails.get(id);
      if (siteInfo == undefined) {
        const siteDetails = new UpdatableSiteDetails(siteWithId, this.userStore, this.farmStore);
        if (this.fetchingArchivedData || siteDetails.kind != 'archived') {
          siteDetails.updateRawData();
        }
        this.siteIdToDetails.set(id, siteDetails);
      } else {
        siteInfo.setObservationSite(siteWithId);
      }
    }

    for (const id of Array.from(this.siteIdToDetails.keys())) {
      if (siteIdsToKeep.has(id) == false) {
        this.siteIdToDetails.delete(id);
      }
    }
  }

  @computed get atLeastOneSalinitySensor(): boolean {
    return this.allSiteDetails.some(
      ({ transformed }) => transformed.status == 'ready' && transformed.value.containsSalinity
    );
  }

  @computed get atLeastOneBoxTemperatureSensor(): boolean {
    return this.allSiteDetails.some(
      ({ transformed }) => transformed.status == 'ready' && transformed.value.containsBoxTemperature
    );
  }

  @computed get hasPrecipitation(): boolean {
    return this.allSiteDetails.some(
      ({ transformed }) =>
        transformed.status == 'ready' &&
        transformed.value.rainfall.length + transformed.value.irrigation.length > 0
    );
  }

  @computed get isLoading(): boolean {
    return this.allSiteDetails.some(({ transformed }) => transformed.status == 'pending');
  }

  @computed get overallScale(): IMinMax | undefined {
    if (this.isLoading) {
      return undefined;
    }

    let result: IMinMax = {
      vwc: { min: 1000, max: -1000 },
      paw: { min: 1000, max: 100 },
    };
    for (const siteDetails of this.allSiteDetails) {
      if (siteDetails.transformed.status != 'ready') {
        continue;
      }
      const { minMax } = siteDetails.transformed.value;
      result = {
        vwc: {
          min: Math.min(minMax.vwc.min, result.vwc.min),
          max: Math.max(minMax.vwc.max, result.vwc.max),
        },
        paw: {
          min: Math.min(minMax.paw.min, result.paw.min),
          max: Math.max(minMax.paw.max, result.paw.max),
        },
      };
    }

    return result;
  }

  getSiteName(siteId: string): string | undefined {
    const siteDetails = this.getSiteInfo(siteId);
    return siteDetails?.site.name;
  }
}

export function createDataLoggerNameMap(configuration: DataLoggerConfiguration): NameMap {
  const { cableTop, cableMiddle, cableMiddleBottom, cableBottom } = configuration;
  const sensorCount = cableMiddleBottom ? 4 : cableMiddle ? 3 : 2;
  const topName = moistureSensorName(cableTop);
  const middleName = moistureSensorName(
    cableMiddle ?? { depth: DEFAULT_DEPTHS_BY_SENSOR_COUNT[sensorCount].cableMiddle! }
  );
  const middleBottomName = moistureSensorName(
    cableMiddleBottom ?? { depth: DEFAULT_DEPTHS_BY_SENSOR_COUNT[sensorCount].cableMiddleBottom! }
  );
  const bottomName = moistureSensorName(cableBottom);

  return {
    [VOLUMETRIC_WATER_CONTENT_TOP]: `Moisture ${topName}`,
    [VOLUMETRIC_WATER_CONTENT_MID]: `Moisture ${middleName}`,
    [VOLUMETRIC_WATER_CONTENT_MID_BOT]: `Moisture ${middleBottomName}`,
    [VOLUMETRIC_WATER_CONTENT_BOT]: `Moisture ${bottomName}`,
    [PLANT_AVAILABLE_WATER_TOP]: `Moisture ${topName}`,
    [PLANT_AVAILABLE_WATER_MID]: `Moisture ${middleName}`,
    [PLANT_AVAILABLE_WATER_BOT]: `Moisture ${bottomName}`,
    [PLANT_AVAILABLE_WATER_MID_BOT]: `Moisture ${middleBottomName}`,
    [SALINITY_TOP]: `EC ${topName}`,
    [SALINITY_MID]: `EC ${middleName}`,
    [SALINITY_BOT]: `EC ${bottomName}`,
    [SALINITY_MID_BOT]: `EC ${middleBottomName}`,
    [TEMPERATURE_TOP]: `Soil temperature ${topName}`,
    [TEMPERATURE_MID]: `Soil temperature ${middleName}`,
    [TEMPERATURE_MID_BOT]: `Soil temperature ${middleBottomName}`,
    [TEMPERATURE_BOT]: `Soil temperature ${bottomName}`,
    [BOX_TEMPERATURE]: 'Box temperature',
  };
}

export function moistureSensorName(configuration: MoistureSensorConfiguration): string {
  const { name, depth } = configuration;
  return name ?? `${depth}cm`;
}

export function percentLabels(...labels: readonly string[]): readonly string[] {
  return labelsWithUnit(labels, '%');
}

export function salinityLabels(...labels: readonly string[]): readonly string[] {
  return labelsWithUnit(labels, 'dS/m');
}

export function temperatureLabels(...labels: readonly string[]): readonly string[] {
  return labelsWithUnit(labels, '°C');
}

function labelsWithUnit(labels: readonly string[], unit: string): readonly string[] {
  return labels.map((each) => `${each} [${unit}]`);
}
