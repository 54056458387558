import { useCallback, useEffect, useMemo, useState } from 'react';
import type { FarmStore } from '../../../dataHandlers/FarmStore';
import { useFarmStore30Days, useFarmStore7Days } from '../../../dataHandlers/RootStore';
import { BASE_URL } from '../../../utils/consts';
import { useFetchWithAuth } from '../utils/fetchWithAuth';

export interface HistoricThreadMessage {
  role: string;
  content: Array<{
    type: string;
    text: {
      value: string;
      annotations: Array<{
        end_index: number;
        file_citation: { file_id: string };
        start_index: number;
        text: string;
        type: string;
      }>;
    };
  }>;
}

export interface Thread {
  threadId: string;
  userId: string;
  createdAt: Date;
  chatType: 'general' | 'irrigation';
  summary?: string;
  siteId?: string;
}

export interface AIState {
  generalChat: boolean | null;
  selectedSiteId: string;
  selectedHistoricThreadId: string | null;
  historicThreadMessages: HistoricThreadMessage[] | null;
  loadingHistoricThread: boolean;
  chatKey: number;
  threads: Thread[];
  cropType: string | null;
  locationType: string | null;
  threadId: string | null;
  existsThreadInLast5Minutes: boolean;
  initialLoading: boolean;
  allThreadsLoading: boolean;
  period: '7days' | '30days' | null;
  aiFarmStore: FarmStore;
}

export interface AIStateActions {
  setGeneralChat: (value: boolean | null) => void;
  setSelectedSiteId: (id: string) => void;
  setSelectedHistoricThreadId: (id: string | null) => void;
  setHistoricThreadMessages: (messages: HistoricThreadMessage[]) => void;
  setLoadingHistoricThread: (loading: boolean) => void;
  setChatKey: React.Dispatch<React.SetStateAction<number>>;
  setThreads: React.Dispatch<React.SetStateAction<Thread[]>>;
  setCropType: (type: string | null) => void;
  setLocationType: (type: string | null) => void;
  fetchAllThreads: () => Promise<void>;
  setThreadId: (id: string | null) => void;
  setPeriod: (period: '7days' | '30days' | null) => void;
  getAndSetSummaryForThread: (threadId: string, content: string) => Promise<void>;
}

export const useAIState = (): [AIState, AIStateActions] => {
  const [generalChat, setGeneralChat] = useState<boolean | null>(null);
  const [selectedSiteId, setSelectedSiteId] = useState('');
  const [selectedHistoricThreadId, setSelectedHistoricThreadId] = useState<string | null>(null);
  const [historicThreadMessages, setHistoricThreadMessages] = useState<HistoricThreadMessage[] | null>(null);
  const [loadingHistoricThread, setLoadingHistoricThread] = useState(false);
  const [chatKey, setChatKey] = useState(0);
  const [threads, setThreads] = useState<Thread[]>([]);
  const [cropType, setCropType] = useState<string | null>(null);
  const [locationType, setLocationType] = useState<string | null>(null);
  const [threadId, setThreadId] = useState<string | null>(null);
  const [existsThreadInLast5Minutes, setExistsThreadInLast5Minutes] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [allThreadsLoading, setAllThreadsLoading] = useState(false);
  const [period, setPeriod] = useState<'7days' | '30days' | null>(null);
  const fetchWithAuth = useFetchWithAuth();
  const farmStore7Days = useFarmStore7Days();
  const farmStore30Days = useFarmStore30Days();

  const aiFarmStore = useMemo(() => {
    return period === '7days' ? farmStore7Days : farmStore30Days;
  }, [period, farmStore7Days, farmStore30Days]);

  // set generalChat state based on
  // the type of thread that is selected in threadId
  useEffect(() => {
    if (threadId && threads.length > 0) {
      const thread = threads.find((thread) => thread.threadId === threadId);
      if (thread) {
        setGeneralChat(thread.chatType === 'general');
        return;
      }
    }
    if (selectedHistoricThreadId && threads.length > 0) {
      const thread = threads.find((thread) => thread.threadId === selectedHistoricThreadId);
      if (thread) {
        setGeneralChat(thread.chatType === 'general');
        return;
      }
    }
  }, [threadId, threads, selectedHistoricThreadId]);

  // useEffect(() => {
  //   if (period) {
  //     aiFarmStore.setSelectedDates(
  //       moment()
  //         .subtract(period === '7days' ? 7 : 30, 'days')
  //         .startOf('day'),
  //       moment().endOf('day')
  //     );
  //   }
  // }, [period, aiFarmStore]);

  const fetchAllThreads = useCallback(async () => {
    setAllThreadsLoading(true);
    try {
      const response = await fetchWithAuth(`${BASE_URL}/llm/threads`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const threadsData = await response.json();
      if (threadsData.error) {
        throw new Error(JSON.stringify(threadsData.error));
      }
      if (!threadsData.threads) {
        throw new Error('Failed to fetch threads');
      }
      setThreads(threadsData.threads as Thread[]);
      // if thread is from the last 5 minutes, set it as the selected thread
      const lastFiveMinutes = new Date(Date.now() - 10 * 60 * 1000);
      const lastFiveMinutesThread = threadsData.threads.find(
        (thread: Thread) => new Date(thread.createdAt) > lastFiveMinutes
      );
      if (lastFiveMinutesThread) {
        setThreadId(lastFiveMinutesThread.threadId as string);
        setExistsThreadInLast5Minutes(true);
      }
      setAllThreadsLoading(false);
    } catch (error) {
      console.error('Error fetching threads', error);
      setAllThreadsLoading(false);
    } finally {
      setAllThreadsLoading(false);
    }
  }, [fetchWithAuth]);

  useEffect(() => {
    if (!initialized) {
      setInitialLoading(true);
      fetchAllThreads();
      setInitialized(true);
      setInitialLoading(false);
    }
  }, [fetchAllThreads, initialized]);

  const getAndSetSummaryForThread = useCallback(
    async (threadId: string, content: string) => {
      const thread = threads.find((thread) => thread.threadId === threadId);
      if (thread?.summary) {
        console.log('Summary already found for thread', threadId);
        return;
      }
      try {
        console.log('Sending first message to a general chat thread with summarization');
        const response = await fetchWithAuth(`${BASE_URL}/llm/threads/generalChat/${threadId}/firstMessage`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            content,
          }),
        });
        const summary = await response.json();
        console.log({ summary });
        if (summary.error || !summary.ok) {
          throw new Error(JSON.stringify(summary.error || 'Failed to get summary'));
        }
        console.log({ summary });
        // set summary on the thread
        setThreads((prevThreads) =>
          prevThreads.map((thread) =>
            thread.threadId === threadId ? { ...thread, summary: summary.summary } : thread
          )
        );
      } catch (error) {
        console.error('Error getting and setting summary for thread', error);
      }
    },
    [fetchWithAuth, threads]
  );

  const aiState = {
    generalChat,
    selectedSiteId,
    selectedHistoricThreadId,
    historicThreadMessages,
    loadingHistoricThread,
    chatKey,
    threads,
    cropType,
    locationType,
    threadId,
    existsThreadInLast5Minutes,
    initialLoading,
    allThreadsLoading,
    period,
    aiFarmStore,
  };

  console.log({ aiState });

  return [
    aiState,
    {
      setGeneralChat,
      setSelectedSiteId,
      setSelectedHistoricThreadId: (id: string | null) => {
        setSelectedHistoricThreadId(id);
        setHistoricThreadMessages(null);
      },
      setHistoricThreadMessages,
      setLoadingHistoricThread,
      setChatKey,
      setThreads,
      setCropType,
      setLocationType,
      fetchAllThreads,
      setThreadId,
      setPeriod,
      getAndSetSummaryForThread,
    },
  ];
};
